body.Default {
  --ion-color-primary: #b3057f;
  --ion-color-primary-rgb: 179,5,127;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #9e0470;
  --ion-color-primary-tint: #bb1e8c;
  --ion-color-secondary: #a10472;
  --ion-color-secondary-rgb: 161,4,114;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #8e0464;
  --ion-color-secondary-tint: #aa1d80;
  --ion-color-tertiary: #8f0465;
  --ion-color-tertiary-rgb: 143,4,101;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #7e0459;
  --ion-color-tertiary-tint: #9a1d74;
  --ion-color-success: #05b339;
  --ion-color-success-rgb: 5,179,57;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #049e32;
  --ion-color-success-tint: #1ebb4d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #b30528;
  --ion-color-danger-rgb: 179,5,40;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #9e0423;
  --ion-color-danger-tint: #bb1e3e;
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-color-medium: #70666d;
  --ion-color-medium-rgb: 112,102,109;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #635a60;
  --ion-color-medium-tint: #7e757c;
  --ion-color-light: #cfccce;
  --ion-color-light-rgb: 207,204,206;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #b6b4b5;
  --ion-color-light-tint: #d4d1d3;
  --ion-color-cleanpink: #b3057f;
  --ion-color-cleanpink-rgb: 179,5,127;
  --ion-color-cleanpink-contrast: #ffffff;
  --ion-color-cleanpink-contrast-rgb: 255,255,255;
  --ion-color-cleanpink-shade: #9e0470;
  --ion-color-cleanpink-tint: #bb1e8c;
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0,0,0;
  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #1a1a1a;
  --ion-color-step-950: #0d0d0d;
}

body.Default .ion-color-cleanpink {
  --ion-color-base: var(--ion-color-cleanpink);
  --ion-color-base-rgb: var(--ion-color-cleanpink-rgb);
  --ion-color-contrast: var(--ion-color-cleanpink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cleanpink-contrast-rgb);
  --ion-color-shade: var(--ion-color-cleanpink-shade);
  --ion-color-tint: var(--ion-color-cleanpink-tint);
}