@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/display.css";
ion-app.mobile .aio-mobile-hide,
ion-app.web-browser .aio-web-browser-hide,
.aioHidden {
  display: none !important;
}

.aio-disabled {
  filter: grayscale(100%);
  opacity: 0.5;
  pointer-events: none;
}

.aio-validation-error {
  color: var(--ion-color-danger);
  --padding-top: 5px;
  --padding-bottom: 5px;
  padding: var(--padding-top, 5px) var(--padding-end, 15px) var(--padding-bottom, 5px) var(--padding-start, 15px);
  text-align: start;
}

ion-content.aio-vertical-align-center::part(scroll),
ion-content.aio-vertical-align-bottom::part(scroll) {
  display: flex;
  padding-top: 0px;
}

ion-content.aio-vertical-align-center::part(scroll) {
  align-items: center;
  padding-bottom: 10px;
}

ion-content.aio-vertical-align-bottom::part(scroll) {
  align-items: flex-end;
  padding-bottom: 0px;
}

.aio-vertical-align-wrapper {
  flex: auto;
}

ion-item.item-label-stacked.item-input > ion-icon[slot=start],
ion-item.item-label-stacked.item-input > ion-icon[slot=end],
ion-item.item-label-floating.item-input > ion-icon[slot=start],
ion-item.item-label-floating.item-input > ion-icon[slot=end],
ion-item.item-label-floating::part(detail-icon),
ion-item.item-label-stacked::part(detail-icon) {
  margin-top: auto;
}

ion-item.item-label-stacked.item-textarea > ion-icon[slot=start],
ion-item.item-label-stacked.item-textarea > ion-icon[slot=end],
ion-item.item-label-floating.item-textarea > ion-icon[slot=start],
ion-item.item-label-floating.item-textarea > ion-icon[slot=end] {
  margin-top: 10px;
}

ion-item.item-label-floating::part(detail-icon),
ion-item.item-label-stacked::part(detail-icon) {
  margin-bottom: 10px;
}

/* Fix Ionic 5 issues */
ion-item ion-reorder:not([slot]) {
  z-index: 100;
}

/** ngx-datatable fix pagination **/
@media only screen and (max-width: 500px) {
  .ngx-datatable.material .datatable-footer .page-count {
    white-space: nowrap;
  }
  .ngx-datatable.material .datatable-footer .pager {
    white-space: nowrap;
  }
}
/* [ETST-42825 Ionic Input Margin Bug] Temporary fix for ion-input width */
:not(ion-item) > ion-input.sc-ion-input-ios-h,
:not(ion-item) > ion-input.sc-ion-input-md-h {
  width: auto;
}

[ng-reflect-router-link] {
  cursor: pointer;
}

/*
* Appery.io component.
*/
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.img-rounded {
  border-radius: 6px;
}

ion-img::part(image) {
  box-sizing: border-box;
}

.aio-component-divider {
  width: 100%;
}

.aio-component-divider::before {
  display: block;
  content: "";
}

ion-toolbar {
  width: auto;
}

ion-toolbar.ios ion-title.title-large {
  text-align: center;
}

ion-item [_aio-chart] {
  width: 100%;
}

.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

:root.md ngx-datatable {
  --aio-toolbar-min-height: 56px;
}
:root.ios ngx-datatable {
  --aio-toolbar-min-height: 44px;
}
:root ngx-datatable.aio-datatable-height-25 {
  height: 25vh;
}
:root ngx-datatable.aio-datatable-height-33 {
  height: 33vh;
}
:root ngx-datatable.aio-datatable-height-50 {
  height: 50vh;
}
:root ngx-datatable.aio-datatable-height-66 {
  height: 66vh;
}
:root ngx-datatable.aio-datatable-height-75 {
  height: 75vh;
}
:root ngx-datatable.aio-datatable-height-100 {
  height: 100vh;
}
:root ngx-datatable.height-100-header {
  height: calc(100vh - var(--aio-toolbar-min-height));
}
:root ngx-datatable.height-100-header-footer {
  height: calc(100vh - 2 * var(--aio-toolbar-min-height));
}
:root .datatable-icon-next {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
:root .datatable-icon-next:before {
  content: "q";
}

ion-item.item-textarea {
  align-items: baseline;
}

ion-item.item-textarea ion-icon:not([slot]) {
  top: 7px;
  position: relative;
}

/*
ETST-42375 bugfix for ion-textarea bug(https://github.com/ionic-team/ionic-framework/issues/21242)
*/
ion-textarea textarea {
  min-height: 58px;
}

/* [ETST-42825 Bug] Temporary fix for ion-textarea width */
:not(ion-item) > ion-textarea.sc-ion-textarea-md-h,
:not(ion-item) > ion-textarea.sc-ion-textarea-ios-h {
  width: auto;
}

/* [ETST-42825 Ionic Search Margin Bug] Temporary fix for ion-search width */
ion-searchbar.sc-ion-searchbar-ios-h,
ion-searchbar.sc-ion-searchbar-md-h {
  width: auto;
}

ion-menu::part(container) {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  ion-modal {
    --border-radius: 8px;
  }
}
/** PWA install modal **/
.aio-modal-install-pwa {
  --border-radius: 8px;
  --max-width: 90%;
  --max-height: 80%;
  --ion-backdrop-color: var(--ion-color-medium);
}

.aio-modal-install-pwa.ios {
  --height: var(--aio-pwa-modal-height-ios, 305px);
}

.aio-modal-install-pwa.android {
  --height: var(--aio-pwa-modal-height-android, 210px);
}

@media (max-width: 767.98px) {
  .aio-modal-install-pwa {
    --width: var(--aio-pwa-modal-width-sm, 300px);
  }
}
@media (min-width: 768px) {
  .aio-modal-install-pwa {
    --width: var(--aio-pwa-modal-width-md, 400px);
  }
}
@media (max-width: 767.98px) {
  .aio_modal_InitialSetup {
    --border-radius: 8px;
    --width: 90%;
    --height: 300px;
  }
}
@media (min-width: 768px) {
  .aio_modal_InitialSetup {
    --width: 500px;
    --height: 300px;
  }
}
@media (min-width: 768px) {
  .aio_modal_Note {
    --width: 100%;
    --max-width: 800px;
    --height: 100%;
    --max-height: 450px;
  }
}
:root {
  /* Set the font family of the entire app */
  --ion-font-family: ubuntu-regular;
}

@font-face {
  font-family: opensans-regular;
  src: url("../assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: ubuntu-regular;
  src: url("../assets/fonts/Ubuntu-Regular.ttf");
}